import CanvasThumbnail from "./CanvasThumbnail";

interface props {
  setActiveArt: React.Dispatch<React.SetStateAction<number>>;
  activeArt: number;
}
const labels = [
  "Revolving Circles",
  "Moving circles",
  "Gravity",
  "Pixel flow",
  "Responsive Cirlces",
  "Colliding Circles",
  "Mouse repeller",
];
export default function ArtSelectorPanel({ setActiveArt, activeArt }: props) {
  return (
    <div
      id="art-selector-panel"
      style={{ overflow: "auto" }}
      className="border-2 rounded-md p-2 flex sm:justify-center items-center  "
    >
      {Array(7)
        .fill(0)
        .map((num, index) => {
          return (
            <CanvasThumbnail
              key={index}
              label={labels[index]}
              setActiveArt={setActiveArt}
              index={index}
              activeArt={activeArt}
            />
          );
        })}
    </div>
  );
}
