import React from "react";
const useMousePosition = () => {
  const mousePosition = React.useRef({ x: 0, y: 0 });
  React.useEffect(() => {
    const updateMousePosition = (ev: MouseEvent) => {
      mousePosition.current.x = ev.clientX;
      mousePosition.current.y = ev.clientY;
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);
  return mousePosition.current;
};
export default useMousePosition;
