import React from "react";

// interface props {
//   setActiveArt(index: number): React.Dispatch<React.SetStateAction<number>>;
//   index: number;
// }
interface props {
	setActiveArt: React.Dispatch<React.SetStateAction<number>>;
	index: number;
	label?: string;
	activeArt: number;
}
export default function CanvasThumbnail({
	setActiveArt,
	index,
	label,
	activeArt,
}: props) {
	function isActive() {
		return index === activeArt;
	}
	return (
		<div
			style={{
				display: "grid",
				color: "white",
				textAlign: "center",
				placeItems: "center",
				userSelect: "none",
				cursor: "pointer",
			}}
			onClick={() => setActiveArt(index)}
			className={`canvas-thumbnail p-1 hover:opacity-80  h-full  ${
				!isActive() ? "bg-slate-400" : "bg-slate-500"
			}`}
		>
			{label}
		</div>
	);
}
